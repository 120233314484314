<template>

<div   id="invoice-page">
    
    <div class="flex flex-wrap items-center justify-between">
    <div class="flex items-center">
      <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
      </div>
      <div class="flex items-center">
        <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
        <vs-button color="success" type="filled" class="mb-base m-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Imprimer</vs-button>
      </div>
    </div>

    <vx-card id="invoice-container">
        <!-- INVOICE METADATA -->
        <div class="vx-row leading-loose p-base">
          <div class="vx-col w-full text-center"><h1 class="font-semibold">SPECIMEN DE LA CARTE D'ADHERENT</h1> </div>  

            <div class="vx-col w-1/2 ">
               <img src="@/assets/images/logo/xtranv_logo.png" alt="vuexy-logo">
            </div>
            <div class="vx-col w-1/2 text-right">
                <div class="invoice__invoice-detail ">
                    <p>DATE :{{'  '}}{{ input.dateOfIssue | moment}}</p>
                </div>
                <div class="invoice__invoice-detail ">
                    <p>EXP :{{'  '}}{{ input.expirationDate | moment}}</p>
                </div>
            </div>
            <div class="vx-col w-5/6  text-left ">
                <h5>Informations personnelles:</h5>
                <b></b>
                <div class="invoice__recipient-info my-2">
                  <p>CODE : <b>{{input.code}}</b></p>
                    <p>NOM & PRÉNOM(S) : <b>{{input.fullName}}</b></p>
                    <p>DATE DE NAISSANCE: <b>{{input.birthdate}}</b></p>
                    <p>SEXE : <b>{{genderObject[input.gender]}}</b></p>
                    <p>ENTREPRISE OU GROUPEMENT : <b>{{input.ownerOfPdv.branch.name }}</b></p>
                </div>
            </div>

            <div class="vx-col w-1/6  text-right ">
                  <h5>Photo</h5>
                  <div class="invoice__company-info my-4">
                  <div class="vx-col" id="avatar-col">
                       <div v-if="image" class="img-container ml-10">
                          <img :src="image" class="rounded w-full " />
                        </div>
                      <!--   <div  class="img-container ml-10">
                          <img src="@/assets/images/logo/xtranv_logo.png" class="rounded w-full " />
                        </div> -->
                    </div>   
                      </div>
               </div>
        </div>

        <p>
            <span class="font-semibold"> Taux de prise en charge </span>  
        </p>
        <br>
        <vs-table hoverFlat class="w-full" :data="input.fullName">
                <template slot="thead">
                  <vs-th class="pointer-events-none">Structure </vs-th>
                  <vs-th class="pointer-events-none">Taux (%)</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr>
                      <vs-td>Centres de santé</vs-td>
                      <vs-td>{{input.rateHealthyCenter}}%</vs-td>
                      
                    </vs-tr>
                    <vs-tr>
                      <vs-td>Hôpitaux</vs-td>
                      <vs-td>{{input.rateHospitals}}%</vs-td>
                     
                    </vs-tr>
                    <vs-tr>
                      <vs-td>Cliniques</vs-td>
                      <vs-td>{{input.rateClinic}}%</vs-td>
                    
                    </vs-tr>
                    <vs-tr>
                      <vs-td>Cabinets</vs-td>
                      <vs-td>{{input.rateMedicalOffice}}%</vs-td>
                      
                    </vs-tr>
                    <vs-tr>
                      <vs-td>Pharmacies</vs-td>
                      <vs-td>{{input.ratePharmacy}}%</vs-td>
                      
                    </vs-tr>
                   
                </template>
        </vs-table>
        <br>
        <p>
          <span class="font-semibold">NB : Ce spécimen est
          valable pour 90 jours à
          compter de la date d’émission. 
          Pour servir et faire valoir ce que 
          de droit.</span>  
        </p>
        <br>
        <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2" >
                  <span class="font-semibold"><U>TransVie</U></span>  
                    <img src="@/assets/images/logo/qr_transvie.jpg" alt="vuexy-logo">
                    <div class="vx-col w-1/4 invoice__recipient-info">
                    </div>
                </div>
                <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2 text-right" >

                      <p class="font-semibold">Cachet et Signature de l’entreprise</p>
                </div>
              </div>
        <br><br><br><br><br><br><br><br><br><br>
        <!--INVOICE FOOTER -->        
        
        <!-- <div class="invoice__footer text-right ">
              <br>
              <p >
                <span>442 avenue Jean Moulin, 82000 Montauban</span>
                <span> SIREN : <span class="font-semibold"> 842530172</span></span>
                <span> Télephone : <span class="font-semibold"> +33581986602</span></span>
              </p>
                <p >
                <span> Email : <span class="font-semibold"> mail@wilinvest.eu</span></span>
              </p>

          </div>
           -->
    </vx-card>

    <div class="flex flex-wrap items-center justify-between">
    <div class="flex items-center">
      
      </div>
      <div class="flex items-center">
        <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
        <vs-button color="success" type="filled" class="mb-base mt-6" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Imprimer</vs-button>
      </div>
    </div>

    </div>
    </template>
<script>
export default{
  data () {
    return {
      input:[],
      image :'',
      genderObject: {
        male: 'Masculin',
        female: 'Féminin'
        
      }
          
    }
  },
  computed: {
  },
  methods: {
    moov () {
      this.$router.push(this.$route.query.redirect || '/Cards')
    },
    printInvoice () {
      document.title = 'SPECIMEN DE LA CARTE D\'ADHERENT'
      window.print()
    }
  },
  components: {},
    
  mounted () {
       
    this.$emit('setAppClasses', 'invoice-page')
    this.input = window.getProspect.invoiceFicheData
    this.image = window.getProspect.image_adherent
    
  }
    
    
}
</script>
    
    <style lang="scss">
    @media print {
    .invoice-page {
      * {
        visibility: hidden;
      }
    
      #content-area {
        margin: 0 !important;
      }
    
      .vs-con-table {
        .vs-con-tbody {
          overflow: hidden !important;
        }
      }
    
      #invoice-container,
      #invoice-container * {
        visibility: visible;
      }
      #invoice-container {
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: none;
      }
    }
    }
    
    @page {
    size: auto;
    }
    </style>
    